import { DateTimeRange } from "../../shared/models/dateTimeRange";
import { PickerShortcutsInput } from "./PickerShortcutsInput";
import * as styles from "./style";

export type PickerShortcutsResolver = () => DateTimeRange;

export interface PickerShortcutsItem {
  label: string;
  getValue: PickerShortcutsResolver;
}

export type PickerShortcutContext = {
  value: string;
  type: "shortcut" | "input";
  resolve: PickerShortcutsResolver;
};

export type PickerShortcutsOnChange = (
  newValue: DateTimeRange,
  context: PickerShortcutContext
) => void;

export interface PickerShortcutsProps {
  items?: PickerShortcutsItem[];
  onChange?: PickerShortcutsOnChange;
}

export const PickerShortcuts = (props: PickerShortcutsProps) => {
  const { items, onChange } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const { label, getValue } = item;

    return {
      label,
      onClick: () => {
        const newValue = getValue();
        onChange?.(newValue, { value: label, type: "shortcut", resolve: getValue });
      },
    };
  });

  return (
    <styles.Container>
      <PickerShortcutsInput onChange={onChange} />
      <styles.ShortcutsList>
        {resolvedItems.map(({ label, onClick }) => (
          <styles.ShortcutsItem key={label} onClick={onClick}>
            {label}
          </styles.ShortcutsItem>
        ))}
      </styles.ShortcutsList>
    </styles.Container>
  );
};
