import { useMemo } from "react";
import { DateTimeRangePicker } from "src/components/shared/DatePickers/DateTimeRangePicker";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { DateTimeRangePickerDemo } from "../shared/DateTimeRangePickerDemo";

export interface DateTimeRangePickerUncontrolledDemoProps {}

export const DateTimeRangePickerUncontrolledDemo = (
  props: DateTimeRangePickerUncontrolledDemoProps
) => {
  const defaultValue = useMemo((): DateTimeRange => [getCurrentDayjs(), getCurrentDayjs()], []);

  return (
    <DateTimeRangePickerDemo title="Uncontrolled Range Picker demo">
      {() => <DateTimeRangePicker defaultValue={defaultValue} format="MM-DD-YYYY HH:mm" />}
    </DateTimeRangePickerDemo>
  );
};
