import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import { DEXBot, RequestRecentParty } from "src/modules/bots";
import ResponseHandler from "src/state/network/ResponseHandler";

export const getDEXBots = async (party?: string) => {
  const url = buildQueryUrl(apiUrl(ApiModules.SwapBots, ""), { party });

  return ResponseHandler.handler<DEXBot[]>({ url, method: "GET" }, "getDEXBots");
};

export const getDEXClients = async (party?: string) => {
  const url = buildQueryUrl("clients", { party });

  return ResponseHandler.handler<string[]>(
    { url: apiUrl(ApiModules.SwapBots, url), method: "GET" },
    "DEXClients"
  );
};

export const getDEXExchanges = async (party?: string) => {
  const url = buildQueryUrl("exchanges", { party });

  return ResponseHandler.handler<string[]>(
    { url: apiUrl(ApiModules.SwapBots, url), method: "GET" },
    "DEXExch"
  );
};

export const getDEXBot = async (bot_uuid: string) =>
  ResponseHandler.handler<DEXBot>(
    { url: apiUrl(ApiModules.SwapBot, `${bot_uuid}`), method: "GET" },
    "getDEXBot"
  );

export const getDEXRecentParties = async () =>
  ResponseHandler.handler<RequestRecentParty[]>(
    {
      url: apiUrl(ApiModules.SwapBots, "/latest"),
      method: "GET",
    },
    "getDEXRecentParties"
  );
