import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { DateTimeRangePickerDemo } from "../shared/DateTimeRangePickerDemo";
import * as styles from "./style";

export interface DateTimeRangeSelectorSyncDemoProps {}

export const DateTimeRangeSelectorSyncDemo = (props: DateTimeRangeSelectorSyncDemoProps) => (
  <DateTimeRangePickerDemo title="Date range selector sync range demo" showValue>
    {({ syncRange, onChange, ...props }) => {
      const refreshRange = () => {
        if (!syncRange) return;
        const newRange = syncRange();
        onChange(newRange, syncRange);
      };
      return (
        <>
          <styles.Container>
            <styles.SampleRangeButton
              onClick={() => {
                onChange([getCurrentDayjs().subtract(1, "week"), getCurrentDayjs().add(1, "week")]);
              }}
              size="m"
            >
              Sample range
            </styles.SampleRangeButton>
            <styles.SyncRangeButton onClick={refreshRange} size="m" disabled={!syncRange}>
              Sync Range
            </styles.SyncRangeButton>
          </styles.Container>
          <DateTimeRangePickerSelector {...props} onChange={onChange} disableFuture />
        </>
      );
    }}
  </DateTimeRangePickerDemo>
);
