import { useState } from "react";
import { CheckBox } from "src/components/shared/CheckBox";
import { DateTimeRangePicker } from "src/components/shared/DatePickers/DateTimeRangePicker";
import { DateTimeRangePickerDemo } from "../shared/DateTimeRangePickerDemo";

export interface DateTimeRangePickerControlledDemoProps {}

export const DateTimeRangePickerControlledDemo = (
  props: DateTimeRangePickerControlledDemoProps
) => {
  const [useAccept, setUseAccept] = useState(true);

  return (
    <DateTimeRangePickerDemo title="Controlled Range Picker demo" showValue>
      {({ syncRange, onChange, value, ...props }) => (
        <>
          <CheckBox
            label="Use accept only"
            checked={useAccept}
            onChange={(e) => setUseAccept(e.target.checked)}
          />
          {useAccept ? (
            <DateTimeRangePicker key="accept" onAccept={onChange} {...props} />
          ) : (
            <DateTimeRangePicker key="control" value={value} onChange={onChange} {...props} />
          )}
        </>
      )}
    </DateTimeRangePickerDemo>
  );
};
