import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { ArbitrageStore } from "src/state/party/arbitrage";
import { OrderBookStore } from "src/state/party/arbitrage/orderbooks/orderbook";
import { PlaceOrdersStore } from "src/state/party/arbitrage/placeOrders";

type ArbitrageProviderProps = ChildrenProps & { party: string };

export const ArbitrageContext = createLateInitContext<ArbitrageStore>();

export const ArbitrageProvider = ({ party, children }: ArbitrageProviderProps) => {
  const state = useLocalStore(ArbitrageStore, party);

  return <ArbitrageContext.Provider value={state}>{children}</ArbitrageContext.Provider>;
};

export const useArbitrageState = () => useLateInitContext(ArbitrageContext);

export const OrderBookContext = createLateInitContext<OrderBookStore>();

export const useOrderBookState = () => useLateInitContext(OrderBookContext);

export const PlaceOrderContext = createLateInitContext<PlaceOrdersStore>();

export const usePlaceOrderState = () => useLateInitContext(PlaceOrderContext);

export const useArbitrageModeState = () => {
  const { arbitrageModeState } = useArbitrageState();

  return arbitrageModeState;
};
