import { rangeToText } from "src/components/shared/DatePickers/shared/utils/time-utils";
import { DateTimeRangePickerDemoWidgetProps } from "..";
import * as styles from "./style";

export interface DateTimeRangePickerPropsPrinterProps
  extends Pick<DateTimeRangePickerDemoWidgetProps, "value"> {}

export const DateTimeRangePickerPropsPrinter = ({
  value,
}: DateTimeRangePickerPropsPrinterProps) => (
  <styles.Container>
    <styles.Text>Value: {rangeToText(value)}</styles.Text>
  </styles.Container>
);
