import { useState } from "react";
import { CheckBox } from "src/components/shared/CheckBox";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DateTimeRangePickerDemo } from "../shared/DateTimeRangePickerDemo";

export interface DateTimeRangeSelectorResponsiveDemoProps {}

export const DateTimeRangeSelectorResponsiveDemo = (
  props: DateTimeRangeSelectorResponsiveDemoProps
) => {
  const [isMobile, setIsMobile] = useState(false);

  const mobileQuery = isMobile ? "(max-width: 10000px)" : "(max-width: 10px)";

  return (
    <DateTimeRangePickerDemo title="Date Range selector responsive demo" showValue>
      {({ syncRange, ...props }) => (
        <>
          <CheckBox
            label="isMobile"
            checked={isMobile}
            onChange={(e) => setIsMobile(e.target.checked)}
          />
          <DateTimeRangePickerSelector {...props} mobileQuery={mobileQuery} />
        </>
      )}
    </DateTimeRangePickerDemo>
  );
};
