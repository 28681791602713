import { useCallback, useMemo, useState } from "react";
import {
  DateTimeRangePickerSelectorProps,
  OnDateTimeRangeChange,
  OnDateTimeRangeSync,
} from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { getDayjsNow } from "src/components/shared/DatePickers/shared/utils/time-utils";
import { DemoCard, DemoCardProps } from "../../../shared/DemoCard";
import { DateTimeRangePickerPropsPrinter } from "./DateTimeRangePickerPropsPrinter";
import * as styles from "./style";

export interface DateTimeRangePickerDemoWidgetProps
  extends Pick<DateTimeRangePickerSelectorProps, "onChange" | "value" | "minDate" | "maxDate"> {
  syncRange?: OnDateTimeRangeSync;
}

interface DateTimeRangePickerDemoProps extends Omit<DemoCardProps, "children"> {
  children?: (props: DateTimeRangePickerDemoWidgetProps) => JSX.Element;
  useUtc?: boolean;
  showValue?: boolean;
}

export const DateTimeRangePickerDemo = ({
  children,
  useUtc = true,
  showValue: showProps,
  ...props
}: DateTimeRangePickerDemoProps) => {
  const [range, setRange] = useState<DateTimeRange>([getDayjsNow(useUtc), getDayjsNow(useUtc)]);
  const [syncRange, setSyncRange] = useState<OnDateTimeRangeSync | undefined>(undefined);

  const defaultMin = useMemo(() => getDayjsNow(useUtc).subtract(1, "month"), [useUtc]);
  const defaultMax = useMemo(() => getDayjsNow(useUtc).add(1, "month"), [useUtc]);

  const onRangeChange: OnDateTimeRangeChange = useCallback((range, sync) => {
    setRange(range);
    setSyncRange(() => sync);
  }, []);

  const widgetProps: DateTimeRangePickerDemoWidgetProps = {
    value: range,
    onChange: onRangeChange,
    syncRange,
    minDate: defaultMin,
    maxDate: defaultMax,
  };

  const Widget = children?.(widgetProps);

  return (
    <DemoCard {...props}>
      <styles.Container>
        {showProps && <DateTimeRangePickerPropsPrinter {...widgetProps} />}
        {Widget}
      </styles.Container>
    </DemoCard>
  );
};
