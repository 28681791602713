import { useCallback, useMemo } from "react";
import { DateTimeRangeFieldProps } from "../DateTimeFields/DateTimeRangeField";
import { DatePickerOnChange, DatePickerRange } from "../shared/models/datePicker";
import {
  dayjsRangeToPickerRange,
  getDayjsNow,
  pickerRangeToDayjsRange,
  PickerRangeToDayjsRangeOptions,
} from "../shared/utils/time-utils";
import { rangeValueManager } from "../shared/utils/valueManager";
import * as styles from "./style";

export interface UseDatePickerProps
  extends Pick<
    DateTimeRangeFieldProps,
    "value" | "onChange" | "maxDate" | "minDate" | "disableFuture"
  > {
  showUTC?: boolean;
}

export type UseDatePickerResponse = {
  value: DatePickerRange;
  onChange: DatePickerOnChange;
  minDate?: Date;
  maxDate?: Date;
};

export interface DatePickerProps extends UseDatePickerProps {}

const useDatePicker = ({
  value: currentRange,
  onChange: onRangeChange,
  minDate: outerMinDate,
  maxDate: outerMaxDate,
  disableFuture,
  showUTC,
}: UseDatePickerProps): UseDatePickerResponse => {
  const adapterOptions = useMemo(
    (): PickerRangeToDayjsRangeOptions => ({
      minDate: outerMinDate,
      maxDate: outerMaxDate,
      disableFuture,
      useUtc: showUTC,
    }),
    [outerMinDate, outerMaxDate, disableFuture, showUTC]
  );

  const dateRange = dayjsRangeToPickerRange(
    currentRange ?? rangeValueManager.emptyValue,
    adapterOptions
  );

  const onDateRangeChange: DatePickerOnChange = useCallback(
    (value) => {
      const valueRange: DatePickerRange = Array.isArray(value) ? value : [value, null];
      const newValue = pickerRangeToDayjsRange(valueRange, adapterOptions);
      onRangeChange?.(newValue, {
        validationError: rangeValueManager.emptyValue,
      });
    },
    [adapterOptions, onRangeChange]
  );

  const maxDate = disableFuture ? getDayjsNow().toDate() : outerMaxDate?.toDate();
  const minDate = outerMinDate?.toDate();

  return {
    value: dateRange,
    onChange: onDateRangeChange,
    minDate,
    maxDate,
  };
};

export const DatePicker = (props: DatePickerProps) => {
  const pickerProps = useDatePicker(props);
  return (
    <styles.Container>
      <styles.DatePicker
        {...pickerProps}
        selectRange
        locale="en-US"
        calendarType="ISO 8601"
        goToRangeStartOnSelect
        showFixedNumberOfWeeks
      />
    </styles.Container>
  );
};
