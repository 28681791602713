import { Story } from "../shared/Story";
import { DateTimeRangePickerControlledDemo } from "./DateTimeRangePickerControlledDemo";
import { DateTimeRangePickerPropsDemo } from "./DateTimeRangePickerPropsDemo";
import { DateTimeRangePickerUncontrolledDemo } from "./DateTimeRangePickerUncontrolledDemo";
import { DateTimeRangeSelectorResponsiveDemo } from "./DateTimeRangeSelectorResponsiveDemo";
import { DateTimeRangeSelectorSyncDemo } from "./DateTimeRangeSelectorSyncDemo";

export interface CalendarStoryProps {}

export const CalendarStory = (props: CalendarStoryProps) => (
  <Story title="Calendar">
    <DateTimeRangePickerUncontrolledDemo />

    <DateTimeRangePickerControlledDemo />

    <DateTimeRangePickerPropsDemo />

    <DateTimeRangeSelectorSyncDemo />

    <DateTimeRangeSelectorResponsiveDemo />

    <div style={{ height: "300px", width: "100%" }} />
  </Story>
);
