import React from "react";
import { BotWarningType, CEXBotWarning } from "src/modules/bots";
import { getFormattedTimeNotWork } from "../utils";
import * as styles from "./style";

interface WarningTooltipProps {
  tooltipId: string;
  warnings: CEXBotWarning[];
}

const warningTitleMap: Record<BotWarningType, string> = {
  [BotWarningType.CriticalBalance]: "Critical Balance",
};

export const WarningTooltip = ({ tooltipId, warnings }: WarningTooltipProps) => (
  <styles.StyledTooltip id={tooltipId}>
    {warnings.map(({ type, msg, downtime }, index) => {
      const isLast = index === warnings.length - 1;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <styles.WarningRow>
            {warningTitleMap[type]} - {msg} - {getFormattedTimeNotWork(downtime)}
          </styles.WarningRow>

          {!isLast && <styles.Divider />}
        </React.Fragment>
      );
    })}
  </styles.StyledTooltip>
);
