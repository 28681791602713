import { useCallback, useEffect, useMemo, useState } from "react";
import { CheckBox } from "src/components/shared/CheckBox";
import {
  DateTimeRangePickerSelector,
  OnDateTimeRangeChange,
} from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { getDayjsNow } from "src/components/shared/DatePickers/shared/utils/time-utils";
import { DemoCard, DemoCardProps } from "../../shared/DemoCard";
import * as styles from "./style";

interface DateTimeRangePickerPropsDemoProps extends Omit<DemoCardProps, "title"> {}

export const DateTimeRangePickerPropsDemo = ({ ...props }: DateTimeRangePickerPropsDemoProps) => {
  const [range, setRange] = useState<DateTimeRange>([getDayjsNow(false), getDayjsNow(false)]);

  const [acceptOnShortcutChange, setAcceptOnShortcutChange] = useState(false);
  const [humanizeShortcut, setHumanizeShortcut] = useState(false);
  const [defaultFormat, setDefaultFormat] = useState(false);
  const [ampm, setAmpm] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [useUTC, setUseUTC] = useState(false);
  const [disableFuture, setDisableFuture] = useState(false);
  const [useMinDate, setUseMinDate] = useState(false);
  const [useMaxDate, setUseMaxDate] = useState(false);

  const onRangeChange: OnDateTimeRangeChange = useCallback((range) => {
    setRange(range);
  }, []);

  useEffect(() => {
    setRange((range) =>
      useUTC
        ? (range.map((date) => (date ? date.utc() : null)) as DateTimeRange)
        : (range.map((date) => (date ? date.local() : null)) as DateTimeRange)
    );
  }, [useUTC]);

  const minDate = useMemo(
    () => (useMinDate ? getDayjsNow(useUTC).subtract(1, "month") : undefined),
    [useMinDate, useUTC]
  );

  const maxDate = useMemo(
    () => (useMaxDate ? getDayjsNow(useUTC).add(1, "month") : undefined),
    [useMaxDate, useUTC]
  );

  const checkBoxProps = [
    {
      label: "Save On Shortcut",
      state: acceptOnShortcutChange,
      setState: setAcceptOnShortcutChange,
    },
    { label: "Humanize Input Shortcut", state: humanizeShortcut, setState: setHumanizeShortcut },
    { label: "Default Format", state: defaultFormat, setState: setDefaultFormat },
    { label: "AM/PM", state: ampm, setState: setAmpm },
    { label: "Show Reset", state: showReset, setState: setShowReset },
    { label: "Show UTC", state: useUTC, setState: setUseUTC },
    { label: "Disable Future", state: disableFuture, setState: setDisableFuture },
    { label: "Use Min Date", state: useMinDate, setState: setUseMinDate },
    { label: "Use Max Date", state: useMaxDate, setState: setUseMaxDate },
  ];

  return (
    <DemoCard title="Date range selector props demo" {...props}>
      <styles.Container>
        <styles.ControlsContainer>
          {checkBoxProps.map((prop) => (
            <CheckBox
              key={prop.label}
              label={prop.label}
              checked={prop.state}
              onChange={(e) => prop.setState(e.target.checked)}
            />
          ))}
        </styles.ControlsContainer>
        <DateTimeRangePickerSelector
          value={range}
          onChange={onRangeChange}
          acceptOnShortcutChange={acceptOnShortcutChange}
          humanizeShortcut={humanizeShortcut}
          defaultFormat={defaultFormat}
          ampm={ampm}
          showReset={showReset}
          showUTC={useUTC}
          disableFuture={disableFuture}
          minDate={minDate}
          maxDate={maxDate}
        />
      </styles.Container>
    </DemoCard>
  );
};
