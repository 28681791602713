import { useUtils } from "@mui/x-date-pickers/internals";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, RefAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { Popup } from "reactjs-popup";
import { PopupProps } from "reactjs-popup/dist/types";
import { DEFAULT_FULL_DATE_FORMAT } from "src/helpers/dateUtils";
import { SetRequired } from "src/helpers/utils";
import useMediaQuery from "src/hooks/useMediaQuery";
import { usePopupState } from "src/hooks/usePopupState";
import {
  DEFAULT_MOBILE_PICKER_QUERY,
  DateTimeRangePicker,
  DateTimeRangePickerProps,
} from "../DateTimeRangePicker";
import {
  PickerShortcutsOnChange,
  PickerShortcutsResolver,
} from "../DateTimeRangePicker/PickerShortcuts";
import { useDefaultizedDateTimeFormat } from "../shared/hooks/useMultiInputDateTimeRangeField";
import { DateTimeRange } from "../shared/models/dateTimeRange";
import { parseDuration } from "../shared/utils/parse-utils";
import { defaultShortcutsItems } from "../shared/utils/shortcuts";
import { dayRangeToDiff, rangeToText } from "../shared/utils/time-utils";
import { rangeValueManager } from "../shared/utils/valueManager";
import {
  SelectorDropdown,
  SelectorDropdownOwnProps,
  SelectorDropdownProps,
} from "./SelectorDropdown";
import "./popupReset.css";

type ActiveShortcut = {
  value: string | null;
  resolve?: PickerShortcutsResolver;
};

type ActiveShortcutText = string | null;

const INITIAL_ACTIVE_SHORTCUT: ActiveShortcut = { value: null };

interface UseDateTimeRangePickerStateParams
  extends Pick<DateTimeRangePickerSelectorProps, "humanizeShortcut"> {}
interface UseDateTimeRangePickerState
  extends Required<Pick<DateTimeRangePickerProps, "value" | "onChange" | "onShortcutChange">> {
  activeShortcut: ActiveShortcut;
}

const useDateTimeRangePickerState = ({
  humanizeShortcut,
}: UseDateTimeRangePickerStateParams): UseDateTimeRangePickerState => {
  const [rangeValue, onRangeChange] = useState<DateTimeRange>(rangeValueManager.emptyValue);

  const [activeShortcut, setActiveShortcut] = useState<ActiveShortcut>(INITIAL_ACTIVE_SHORTCUT);

  const onShortcutChange: PickerShortcutsOnChange = useCallback(
    (newValue, context) => {
      const { value: shortcut, type, resolve } = context;
      switch (type) {
        case "shortcut": {
          setActiveShortcut({ value: shortcut, resolve });
          break;
        }
        case "input": {
          if (!humanizeShortcut) {
            setActiveShortcut({ value: null, resolve });
            break;
          }
          const { duration, error } = parseDuration(shortcut);
          if (error) {
            setActiveShortcut(INITIAL_ACTIVE_SHORTCUT);
            break;
          } else {
            const humanizedShortcut = `${dayjs.duration(duration).humanize()} ago`;
            setActiveShortcut({ value: humanizedShortcut, resolve });
          }
          break;
        }
      }
      onRangeChange(newValue);
    },
    [humanizeShortcut]
  );

  const onChange = useCallback((value: DateTimeRange) => {
    onRangeChange(value);
    setActiveShortcut(INITIAL_ACTIVE_SHORTCUT);
  }, []);

  return {
    value: rangeValue,
    onChange,
    onShortcutChange,
    activeShortcut,
  };
};

interface UseDateTimeRangePickerSelectorStateParams
  extends Pick<
    DateTimeRangePickerSelectorProps,
    "value" | "defaultOpen" | "format" | "ampm" | "shortcuts"
  > {}

interface UseDateTimeRangePickerSelectorState {
  rangeText: string | null;
  onActiveShortcutChange: Dispatch<ActiveShortcutText>;
  showPicker: boolean;
  onOpenPicker: () => void;
  onClosePicker: () => void;
}

const useDateTimeRangePickerSelectorState = ({
  value,
  defaultOpen,
  shortcuts = [],
  ...props
}: UseDateTimeRangePickerSelectorStateParams): UseDateTimeRangePickerSelectorState => {
  const [showPicker, { onOpen: onOpenPicker, onClose: onClosePicker }] = usePopupState(defaultOpen);

  const [activeShortcut, onActiveShortcutChange] = useState<ActiveShortcutText>(null);

  const dateFormat = useDefaultizedDateTimeFormat<Dayjs>(props);

  const resolvedItems = shortcuts.map((item) => {
    const duration = dayRangeToDiff(item.getValue());
    const { label } = item;
    return { label, value: duration };
  });

  const rangeText = useMemo(() => {
    if (activeShortcut) {
      return activeShortcut;
    }
    const currentDiff = dayRangeToDiff(value);
    const [, end] = value;
    const isEndNow = end ? end.diff(dayjs(), "seconds") < 10 : false;
    const shortcutMatch = resolvedItems.find(
      ({ value: shortcutDiff }) => shortcutDiff === currentDiff
    );
    if (shortcutMatch && isEndNow) {
      return shortcutMatch.label;
    }
    return value ? rangeToText(value, dateFormat) : null;
  }, [activeShortcut, dateFormat, resolvedItems, value]);

  return {
    rangeText,
    onActiveShortcutChange,
    onOpenPicker,
    onClosePicker,
    showPicker,
  };
};

interface UseDateTimeRangePickerSelectorParams
  extends SetRequired<
    Pick<
      DateTimeRangePickerSelectorProps,
      | "value"
      | "onChange"
      | "mobileQuery"
      | "format"
      | "ampm"
      | "humanizeShortcut"
      | "popupProps"
      | "shortcuts"
    >,
    "mobileQuery" | "popupProps"
  > {}

interface UseDateTimeRangePickerSelectorResponse {
  pickerProps: Pick<
    DateTimeRangePickerProps,
    "value" | "onChange" | "onAccept" | "onShortcutChange" | "mobileQuery"
  >;
  selectorProps: SelectorDropdownOwnProps;
  popupProps: Pick<PopupProps, "onOpen" | "onClose" | "open">;
}

const useDateTimeRangePickerSelector = ({
  value,
  onChange,
  mobileQuery,
  humanizeShortcut,
  popupProps: outerPopupProps,
  ...props
}: UseDateTimeRangePickerSelectorParams): UseDateTimeRangePickerSelectorResponse => {
  const utils = useUtils<Dayjs>();

  const isMobile = useMediaQuery(mobileQuery);

  const { rangeText, onActiveShortcutChange, onClosePicker, onOpenPicker, showPicker } =
    useDateTimeRangePickerSelectorState({ value, ...props });

  const {
    value: pickerValue,
    onChange: pickerOnChange,
    onShortcutChange,
    activeShortcut: pickerActiveShortcut,
  } = useDateTimeRangePickerState({ humanizeShortcut });

  useEffect(() => {
    if (!rangeValueManager.areValuesEqual(utils, pickerValue, value)) {
      pickerOnChange(value);
      // reset shortcut imperatively without waiting for picker
      onActiveShortcutChange(null);
    }
    // not using picker value as a dependency here because unidirectional syncing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, utils]);

  const onAccept = useCallback(
    (newValue: DateTimeRange) => {
      onChange(newValue, pickerActiveShortcut?.resolve);
      onActiveShortcutChange(pickerActiveShortcut?.value || null);
      onClosePicker();
    },
    [onActiveShortcutChange, onChange, onClosePicker, pickerActiveShortcut]
  );

  const pickerProps = {
    onShortcutChange,
    onAccept,
    onChange: pickerOnChange,
    value: pickerValue,
    mobileQuery,
  };

  const selectorProps: SelectorDropdownOwnProps = {
    title: rangeText,
    open: showPicker,
    size: isMobile ? "small" : "large",
  };

  // reset fix for absolute left on mobile
  const popupResetClass = isMobile ? "popup-reset" : undefined;
  const popupClass = [popupResetClass, outerPopupProps.className].filter(Boolean).join(" ");

  const popupProps = {
    onOpen: onOpenPicker,
    onClose: onClosePicker,
    className: popupClass || undefined,
    open: showPicker,
    position: outerPopupProps.position,
  };

  return {
    pickerProps,
    selectorProps,
    popupProps,
  };
};

interface UseDefaultizedFormatProps
  extends Pick<DateTimeRangePickerSelectorProps, "defaultFormat" | "format" | "ampm"> {}

interface UseDefaultizedFormatResponse extends Pick<DateTimeRangePickerProps, "format" | "ampm"> {}

const useDefaultizedFormatProps = (
  props: UseDefaultizedFormatProps
): UseDefaultizedFormatResponse => ({
  ampm: props.ampm,
  format: props.defaultFormat ? DEFAULT_FULL_DATE_FORMAT : props.format,
});

export type OnDateTimeRangeSync = () => DateTimeRange;

export type OnDateTimeRangeChange = (range: DateTimeRange, syncRange?: OnDateTimeRangeSync) => void;

export interface DateTimeRangePickerSelectorOwnProps
  extends SetRequired<
    Omit<DateTimeRangePickerProps, "onAccept" | "defaultValue" | "onShortcutChange" | "onChange">,
    "value"
  > {
  onChange: OnDateTimeRangeChange;
  defaultOpen?: boolean;
  humanizeShortcut?: boolean;
  defaultFormat?: boolean;
  popupProps?: Pick<PopupProps, "className" | "position">;
  selectorDropdown?: React.ComponentType<SelectorDropdownProps & RefAttributes<HTMLDivElement>>;
}
export interface DateTimeRangePickerSelectorProps extends DateTimeRangePickerSelectorOwnProps {}

export const DateTimeRangePickerSelector = ({
  value,
  defaultOpen,
  onChange,
  mobileQuery = DEFAULT_MOBILE_PICKER_QUERY,
  acceptOnShortcutChange = true,
  humanizeShortcut = false,
  defaultFormat = true,
  ampm,
  format,
  popupProps: outerPopupProps = {},
  shortcuts = defaultShortcutsItems,
  selectorDropdown,
  ...props
}: DateTimeRangePickerSelectorProps) => {
  const formatProps = useDefaultizedFormatProps({
    defaultFormat,
    ampm,
    format,
  });
  const { pickerProps, popupProps, selectorProps } = useDateTimeRangePickerSelector({
    value,
    onChange,
    mobileQuery,
    humanizeShortcut,
    popupProps: outerPopupProps,
    shortcuts,
    ...formatProps,
  });

  const pickerFieldProps: DateTimeRangePickerProps = {
    ...pickerProps,
    ...props,
    ...formatProps,
    acceptOnShortcutChange,
  };

  const selectorFieldProps: SelectorDropdownProps = {
    ...selectorProps,
  };

  const Selector = selectorDropdown || SelectorDropdown;

  return (
    <Popup
      {...popupProps}
      trigger={<Selector {...selectorFieldProps} />}
      arrow={false}
      closeOnDocumentClick
      offsetY={10}
      nested
      contentStyle={{ padding: "10px" }}
    >
      <DateTimeRangePicker {...pickerFieldProps} />
    </Popup>
  );
};
