import { ComponentPropsWithoutRef } from "react";
import { Link } from "react-router-dom";
import { LinkButton } from "src/components/shared/Buttons/v2/LinkButton";
import * as styles from "./style";

export interface StoryProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
}

export const Story = ({ title, children, ...props }: StoryProps) => (
  <styles.StoryContainer {...props}>
    <LinkButton color="neutral" to="/stories" style={{ alignSelf: "flex-start" }} as={Link}>
      Go back
    </LinkButton>
    <styles.StoryTitle>{title}</styles.StoryTitle>
    <styles.StoryContent>{children}</styles.StoryContent>
  </styles.StoryContainer>
);
