import { makeAutoObservable } from "mobx";
import { IArbitrageTerminalUpdater, UpdateFunc } from "../types";

export class UpdaterStore<T extends string> implements IArbitrageTerminalUpdater {
  private _updaters: Map<T, UpdateFunc> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  updateFetchData = () => {
    this._updaters.forEach((updater) => {
      updater();
    });
  };

  addUpdater = (key: T, updFunc: UpdateFunc) => {
    this._updaters.set(key, updFunc);
  };

  removeUpdater = (key: T) => {
    this._updaters.delete(key);
  };
}
